<template>
  <keep-alive>
    <div class="col-sm-12 col-xl-6 col-xxl-4 mb-3 min-width-home">
      <div v-bind:class="darkMode" class="card p-3">
        <div class="row">
          <div class="col">
            <h4 class="no-overflow">{{ title }}</h4>
          </div>
        </div>
        <div class="row p-2 d-flex justify-content-between nowrap">
          <div v-if="menu == null" class="row d-flex justify-content-between nowrap">
            <content-loader class="col-12 col-sm-4" v-for="index in 3" :key="index" :width="400" :height="125" :speed="2" primaryColor="#b0b0b0" secondaryColor="#ecebeb">
              <rect x="80" y="73" rx="3" ry="3" width="254" height="6" />
              <rect x="78" y="88" rx="3" ry="3" width="254" height="6" />
              <rect x="150" y="103" rx="3" ry="3" width="118" height="6" />
              <circle cx="210" cy="27" r="22" />
            </content-loader>
          </div>
          <div v-else v-for="item in menu" :key="item.id" class="col-12 col-sm-4">
            <router-link :to="`/order/menu/category/${item.id}`" v-bind:class="darkMode" class="btn shadow-5 mb-4 d-flex justify-content-center flex-column">
              <div class="row mb-2">
                <img :src="getIcon(item.category_data.name)" height="50px" />
              </div>
              <div class="row">
                <h6 class="m-0 no-overflow">{{item.category_data.name}}</h6>
              </div>
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex justify-content-end">
            <router-link v-bind:class="darkModeBtn" class="btn col col-sm-12 col-md-4 col-lg-4 col-xl-6" to="/order">View more</router-link>
          </div>
        </div>
      </div>
    </div>
  </keep-alive>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import { darkModeStyle } from '../../assets/js/darkMode';
import icons from '../../assets/js/icons';

export default {
  name: 'OrderMenu',
  components: {
    ContentLoader
  },
  data() {
    return {
      title: 'Order now',
      menu: null,
      darkMode: darkModeStyle(),
      darkModeBtn: darkModeStyle('btn-dark', 'btn-light')
    }
  },
  methods: {
    async getMenu() {
      const menuJSON = await this.$store.dispatch('getCategoriesList');
      return menuJSON;
    },
    async loadMenu() {

      const menu = this.$store.getters.featuredMenu;

      if (menu == null) {
        const featuredMenu = await this.getMenu();
        this.$store.commit('setFeaturedMenu', featuredMenu);
        return this.sortMenu(featuredMenu);
      }

      return this.sortMenu(menu);
    },
    sortMenu(menuJSON) {
      const objects = menuJSON.sort(() => 0.5 - Math.random());
      const selected = objects.slice(0, 3);

      return selected;
    },
    getIcon(name) {
      return icons[name];
    }
  },
  async mounted() {
    this.menu = await this.loadMenu();
  }
}
</script>